.sidebar {
  background: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 4.5vw;
  min-width: 40px;
  height: calc(100vh - 30px - 25px);
}

.iconContainer {
  cursor: pointer;
  width: 100%;
}

.iconContainer:hover {
  background: var(--sidebar-hover-bg);
}

.active {
  border-left: 2px solid var(--accent-color);
}

.icon {
  height: 48px;
  width: 48px;
  padding: 0.65rem 0;
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 2000px) {
  .sidebar {
    width: 2.5vw;
  }
}

@media screen and (max-width: 1100px) {
  .sidebar {
    width: 6vw;
  }
}

@media screen and (max-width: 900px) {
  .sidebar {
    width: 8vw;
  }
  .icon {
    height: 48px;
    width: 48px;
    padding: 0.6rem;
  }
}

@media screen and (max-width: 600px) {
  .sidebar {
    width: 10vw;
  }
  .icon {
    height: 40px;
    width: 40px;
    padding: 0.6rem;
  }
}
