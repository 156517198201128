@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;600&display=swap");

.main {
  display: flex;
  background: var(--main-bg);
}

.content {
  padding: 2rem;
  color: var(--text-color);
  font-family: "JetBrains Mono", monospace;
  flex: 1;
  height: 85vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.content {
  scrollbar-width: 10px;
  scrollbar-color: var(--accent-color);
}

.content::-webkit-scrollbar {
  width: 15px;
}

.content::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg);
  border-left: 1px solid #1e1f29;
}

.content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg);
  border-left: 1px solid #1e1f29;
}

@media screen and (max-width: 600px) {
  .content {
    padding: 2rem 1rem;
  }
}
