.headings {
  color: var(--accent-color);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.title {
  color: var(--accent-color);
  margin-bottom: 0.25rem;
}
