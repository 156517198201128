.container {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.underline {
  cursor: pointer;
  color: white;
  text-decoration: none;
  background-image: linear-gradient(
    120deg,
    var(--accent-color) 100%,
    var(--accent-color) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
}

.underline:hover {
  background-size: 100% 88%;
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
