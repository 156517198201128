.container {
	display: flex;
	justify-content: center;
	gap: 2rem;
}

.container > * {
	flex: 1;
}

.container > div:first-child {
	border-right: 1px solid var(--accent-color);
}

.container h1 {
	margin-bottom: 2rem;
	font-size: 1.5rem;
}

.contactForm {
	margin-right: 10px;
}

.flex {
	display: flex;
	gap: 1rem;
}

.form label {
	display: block;
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 0.8rem;
}

.form input,
.form textarea {
	font-family: "JetBrains Mono";
	margin-bottom: 1.5rem;
	background: var(--article-bg);
	color: var(--text-color);
	border: none;
	font-size: 1rem;
	padding: 0.75rem;
	width: 100%;
	resize: none;
}

.form input:focus,
.form textarea:focus {
	outline: 1px solid var(--accent-color);
	margin-right: 2rem;
}

.form button {
	background: var(--accent-color);
	color: var(--main-bg);
	border: none;
	cursor: pointer;
	padding: 0.5rem 1rem;
	font-size: 0.9rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-right: 2rem;
}

.heading {
	margin-bottom: 1.5rem;
}

.rightMargin {
	margin-right: 2rem;
}

@media screen and (max-width: 1200px) {
	.container {
		display: block;
	}
	.container > div:first-child {
		border-right: 2rem;
		margin-bottom: 2.5rem;
	}
}

@media screen and (max-width: 600px) {
	.flex {
		display: block;
	}
	.form input {
		font-size: 0.85rem;
	}
}
