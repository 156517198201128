@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap");

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Product Tags */
.React {
	border: 0.1rem solid rgb(97, 218, 251);
}
.React:hover {
	background: rgba(97, 218, 251, 0.25);
}

.VSCode {
	border: 0.1rem solid rgb(0, 122, 204); /* A VS Code blue for the border */
}
.VSCode:hover {
	background: rgba(
		0,
		122,
		204,
		0.25
	); /* Light blue for the hover background */
}

.TypeScript {
	border: 0.1rem solid rgb(0, 82, 204); /* A TypeScript blue for the border */
}
.TypeScript:hover {
	background: rgba(
		0,
		82,
		204,
		0.25
	); /* Light blue for the hover background */
}

.CSharp {
	border: 0.1rem solid rgb(101, 57, 172);
}

.CSharp:hover {
	background: rgba(101, 57, 172, 0.25);
}

.VBNet {
	border: 0.1rem solid rgb(86, 156, 214);
}
.VBNet:hover {
	background: rgba(86, 156, 214, 0.25);
}

.Scrum {
	border: 0.1rem solid rgb(60, 179, 113);
}
.Scrum:hover {
	background: rgba(60, 179, 113, 0.25);
}

.MurderMystery {
	border: 0.1rem solid rgb(255, 0, 0);
}
.MurderMystery:hover {
	background: rgba(255, 0, 0, 0.25);
}

.JavaScript {
	border: 0.1rem solid rgb(240, 219, 79);
}
.JavaScript:hover {
	background: rgba(240, 219, 79, 0.25);
}

.CSS {
	border: 0.1rem solid rgb(0, 151, 167);
}
.CSS:hover {
	background: rgba(0, 151, 167, 0.25);
}
