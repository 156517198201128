/* .correct {
	background-color: green;
}

.present {
	background-color: #f3c303;
}

.absent {
	background-color: grey;
} */

/* Game container styles */
.game-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: Arial, sans-serif;
}

/* Word grid styles */
.word-grid {
	margin-bottom: 20px;
}

.word-row {
	display: flex;
	margin-bottom: 5px;
}

.letter {
	width: 40px;
	height: 40px;
	border: 2px solid #ccc;
	text-align: center;
	line-height: 40px;
	font-size: 20px;
	margin: 2px;
	text-transform: uppercase;
}

.correct {
	background-color: #6aaa64; /* Green for correct letters */
	border-color: #6aaa64;
	color: white;
}

.present {
	background-color: #c9b458; /* Faded yellow for present letters */
	border-color: #c9b458;
	color: white;
}

.absent {
	background-color: #787c7e; /* Grey for absent letters */
	border-color: #787c7e;
	color: white;
}

/* Keyboard styles */
.keyboard {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.keyboard-row {
	display: flex;
	margin-bottom: 5px;
}

.keyboard-button {
	padding: 10px;
	margin: 2px;
	font-size: 16px;
	width: 40px;
	height: 50px;
	background-color: #f0f0f0;
	border: 2px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	text-transform: uppercase;
	transition: background-color 0.3s, border-color 0.3s;
}

.keyboard-button:hover {
	background-color: #e0e0e0;
}

.special-button {
	width: 120px;
	background-color: #0ed2e0;
}

.correct.keyboard-button {
	background-color: #6aaa64; /* Green for correct letters */
	border-color: #6aaa64;
	color: white;
}

.present.keyboard-button {
	background-color: #c9b458; /* Faded yellow for present letters */
	border-color: #c9b458;
	color: white;
}

.absent.keyboard-button {
	background-color: #787c7e; /* Grey for absent letters */
	border-color: #787c7e;
	color: white;
}
